// Plugin for Month select
/* jquery ui datepicker month year picker */
.ui-datepicker .ui-datepicker-select-month td ,
.ui-datepicker .ui-datepicker-select-year td {
	line-height: 3.4em;
}
.ui-datepicker .ui-datepicker-select-month td span,
.ui-datepicker .ui-datepicker-select-month td a,
.ui-datepicker .ui-datepicker-select-year td span,
.ui-datepicker .ui-datepicker-select-year td a  {
	font-size: 1.5em;
	text-align: center;
}
.ui-datepicker .ui-datepicker-select-year td.outoffocus {
	opacity: 0.5;
}


.ui-datepicker.ui-widget {
	.ui-datepicker-header {
		background: none;
		border: none;
		.ui-datepicker-title {
			font-weight: 400;
			a {

			}
		}
		.ui-state-hover {
			background: none;
			border: none;
			top: 2px;
			&.ui-datepicker-next {
				right: 2px;
			}
			&.ui-datepicker-prev {
				left: 2px;
			}
		}
	}
	th {
		padding: 5px;
		font-weight: 400;
		background: $blue-light;
		color: $white;
	}
	td {
		a {
			color: $black-light;
			background: none;
			&.ui-state-active {
				background: $primary;
				border: 1px solid $primary;
				color: $white;
			}
			&.ui-state-highlight {
				background: $blue-light;
				border: 1px solid $blue-light;
				color: $white;
			}
		}
	}
}