.admin-filter,
.admin-filter-box {
	display: none;
}

body.page60,
body.page61,
body.page62 {
	.admin-filter {
		display: block;
	}
}

.admin-filter-box {
	position: absolute;
	padding: 10px 20px 0;
	top: 40px;
	left: -1px;
	width: 350px;
	background: $white;
	box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
	h4 {
		font-size: 12px;
		font-weight: 600;
		text-transform: none;
		label {
			font-weight: 600;
		}
	}
	hr {
		margin: 10px 0;
	}
	label {
		margin-bottom: 10px;
	}
	.project-sword,
	.project-category,
	.company-sword,
	.partner-sword {
		width: 250px;
		border: 1px solid $grey;
	}
	input[type=checkbox] {
		margin: 0 10px 0 0;
	}
	@media (max-width: $mobile-max) {
		left: auto;
		right: 0;
		width: 245px;
		.project-sword,
		.project-category,
		.company-sword,
		.partner-sword {
			width: 205px;
		}
	}
}
input[type=checkbox],
input[type=radio] {
	-webkit-appearance: none;
	width: 16px;
	height: 16px;
	border-radius: $global-radius;
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: center center;
	margin-right: 10px;
	margin-top: 1px;
	margin-bottom: 1px;
	float: left;
}

input[type=checkbox] {
	background-image: url('/assets/img/project/admin-icons/check-box-unactive.png');
	&:checked {
		background-image: url('/assets/img/project/admin-icons/check-box-active.png');
	}
}

input[type=radio] {
	margin-top: 0;
	background-image: url('/assets/img/project/admin-icons/radio-box-unactive.png');
	&:checked {
		background-image: url('/assets/img/project/admin-icons/radio-box-active.png');
	}
}

.admin-list-filter-toggle {
	cursor: pointer;
	width: 150px;
	font-weight: 400;
	padding-left: 30px;
	background-image: url('/assets/img/project/admin-icons/ic-filter-b.png');
	background-repeat: no-repeat;
	background-size: auto 18px;
	background-position: left top;
	margin: 0;
	padding: 0 0 20px 30px;
}

.admin-list-filter-box {
	display: none;
	h3.div-trigger {
		font-size: 18px;
		line-height: 1;
		padding: 10px 10px;
		text-transform: uppercase;
		font-weight: 400;
		&:after {
			width: 18px;
			height: 18px;
			top: 5px;
			right: 15px;
		}
	}

	.columns-group-2 {
		columns: 2;
	}

	.checkbox-group,
	.columns-group-3 {
		columns: 3;
	}
	.columns-group-4 {
		columns: 4;
	}
	.default-label-small {
		//width: 30%;
		//margin-right: 3%;
		display: block;
		padding-left: 26px;
		vertical-align: top;
		input {
			margin-left: -26px;
		}
	}
	.panel-group {
		border: none;
		.panel {
			//width: 30%;
			//margin: 0 1% !important;
			//float: left;
			//&:nth-child(3n+1) {
			//	clear: both;
			//}
			h4 {
				a {
					padding: 10px;

				}
			}
			.panel-body {
				margin-left: 10px;
			}
			.panel {
				width: 100%;
				float: none;
			}
		}
		&:after {
			content: '';
			display: block;
			clear: both;
		}
	}
	.checkbox-description {
		padding-left: 0;
	}

	@media (max-width: 767px) {
		.checkbox-group,
		.columns-group-2,
		.columns-group-3,
		.columns-group-4 {
			columns: 1;
		}
		.default-label-small {
			//width: 100%;
			//margin-right: 0;
			//clear: both;
		}
		.panel-group {
			.panel {
				width: 100%;
				margin: 0!important;
				float: none;
				.panel {
					width: 100%;
					float: none;
				}
				.default-label-small {
					width: 100%;
					margin-right: 0;
				}
			}
			&:after {
				content: '';
				display: block;
				clear: both;
			}
		}
	}
}

.filter-all-reset {
	display: block;
	float: left;
	margin: -39px 0 0 155px;
	background: $red;
	padding: 5px 15px;
	color: $white;
	text-transform: uppercase;
	font-size: 14px;
	line-height: 14px;
	cursor: pointer;
	border-radius: 2px;
}

.filter-radio-reset {
	display: block;
	background: $red;
	padding: 5px 10px;
	text-transform: uppercase;
	color: $white;
	width: 70px;
	font-size: 12px;
	line-height: 1;
	margin-top: 5px;
	cursor: pointer;
	border-radius: 2px;
	text-align: center;
}

.admin-template-wrapp .checkbox-description,
.admin-template-wrapp label small{
	display: none !important;
}