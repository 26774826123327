// Import Basic
@import "/assets/bower_components/select2/dist/css/select2.css";
@import "/assets/bower_components/jquery-ui/themes/base/all.css";
@import "admin/constants";
@import "admin/mixins";
@import "admin/l-header";
@import "admin/l-footer";
@import "admin/l-page";
@import "admin/m-filter";
@import "admin/m-cta";
@import "admin/m-tabs";
@import "admin/m-datepicker";
@import "admin/m-accordion";

* {
	box-sizing: border-box;
	&:focus {
		outline:0 !important;
	}
}

body {
	margin:0 auto;
	padding: 40px 0 30px;
	background: $grey;
	font-family: $body-font;
}

/* Grid */
.admin-container {
	padding:0;
	margin-top:0;
}

/* Navigation */
.admin-main-navigation {
	position: fixed;
	z-index: 10;
	width: 220px;
	background: $blue-dark;
	left: 0;
	top: 40px;
	bottom: 0;
	padding-left:0;
	transition: all 1s cubic-bezier(0.17, 0.04, 0.03, 0.94);
	&-content {
		float: left;
		height: 100%;
		width: 195px;
		padding-top: 20px;
		ul {
			padding: 0;
			li {
				opacity: 0.7;
				width: 100%;
				height: 44px;
				border-bottom: 1px solid $blue-light;
				margin: 0;
				background-repeat: no-repeat;
				background-size: 18px 16px;
				background-position: 15px center;
				a {
					background: none;
					font-weight: 300;
					text-transform: none;
					font-size: 14px;
					line-height: 19px;
					cursor: pointer;
					text-align: left;
					padding-left: 40px;
				}
				&.active,
				&:hover {
					opacity: 1;
					background-color: $blue-light;
					a {
						background: none;
					}
				}
				&.linkitem-li-60 {
					background-image: url('/assets/img/project/admin-icons/ic-project.png');
				}
				&.linkitem-li-61 {
					background-image: url('/assets/img/project/admin-icons/ic-company.png');
				}
				&.linkitem-li-62 {
					background-image: url('/assets/img/project/admin-icons/ic-partners.png');
				}
				&.linkitem-li-63 {
					background-image: url('/assets/img/project/admin-icons/ic-messages.png');
				}
				&.linkitem-li-64 {
					background-image: url('/assets/img/project/admin-icons/ic-documents.png');
				}
				&.linkitem-logo {
					background-image: url('/assets/img/project/logo-white-crm.png');
					background-position: 15px 11px;
				}
			}
		}
	}
	&-toggle {
		background: $blue;
		float: left;
		height: 100%;
		width: 25px;
		.toggle-button {
			cursor: pointer;
			position: relative;
			top: 225px;
			background: $blue-light;
			text-align: center;
			line-height: 100px;
			color: $white;
		}
	}

	@media (max-width: $mobile-max) {
		left: -195px;
	}
}

.admin-main-navigation li {
	width:90px;
	height:90px;
	display:table;
	margin-bottom:1px;
}

.admin-main-navigation li a {
	display:table-cell;
	vertical-align:middle;
	text-align:center;
	font-size:12px;
	padding:5px;
	background:#333;
	color:#fff;
	text-decoration:none;
	text-transform:uppercase;
}

.admin-main-navigation li a:hover,.admin-main-navigation li.active a {
	background:#cc0800;
	color:#fff;
}

/* TYPO */
h1,h2,h3,h4 {
	text-transform:uppercase;
	font-family: $body-font;
}

h1 {
	font-size:32px;
	margin-top:0;
	& + p {
		font-size: 14px;
		padding: 0 30px;
		position: relative;
		top: -30px;
	}
}

h2 {
	font-size:24px;
	margin-top:30px;
}

h3 {
	font-size:18px;
	margin-top:20px;
}

h4 {
	font-size:14px;
	margin:0;
}

/* GRID */
.admin-side-menu {
	width:15%;
}

.admin-content {
	width:80%;
}


.label-pm {
}

.tooltip {
	width: 300px;
	max-width: 300px;
	opacity: 1 !important;
	&-inner {
		max-width: none;
		background: rgba(0,0,0,0.6);
	}
	&-arrow {
		border-top-color: rgba(0,0,0,0.6) !important;
	}
}

/*
.admin-table-opening-times,
.admin-table-files {
    margin-bottom: 20px;
    border:1px solid #ddd;
    td,th {
        padding:5px;
        border:1px solid #ddd;
        text-align: left;
    }
    th {
        padding:10px 5px;
        background:#333;
        color:#ddd;
    }
}
*/
/* NAVIGATION */
.admin-nav-item {
	display:block;
}

.admin-nav-link {
	display:block;
	padding:10px 20px;
	color:#000;
	background:#ddd;
}

.admin-nav-link-active {
	color:#fff;
	background:#333;
}

/* FORMS */

.crm-form-wrap {
	h4 {
		margin: 20px 0 15px;
	}
}

.crm-simple-form {
	.div-trigger {
		&:after {
			display: none;
		}
	}
}

.form-group {
    margin-bottom: 0;
    label {
        padding-top: 8px;
    }
}
label {
    font-weight: normal;
	line-height: 18px;
	small {
		color: $grey-dark;
	}
}

.form-control {
    background-color: #eee;
    height: auto;
    padding: 8px 20px;
    margin-bottom: 20px;
    box-shadow: none;
    font-weight: 300;
    border-radius: $global-radius;
}

p, span {
	&.admin-fielddescription {
		display: block;
		line-height: 1.2;
		margin-bottom: 15px;
		font-size: 12px;
		opacity: 0.8;
		&.error {
       		display: inline-block;
            color: #fff;
            position: relative;
            top: -5px;
            background-color: red;
            padding: 3px 5px;
            border-radius: 2px;
			position: relative;
			top: -5px;
		}
	}
}

.checkbox-group {
    span.error.admin-fielddescription {
        margin: 15px 0;
	}
}

.checkbox-error-message {
//     margin: 10px 15px;
}

.form-group + .admin-fielddescription {
	padding-left: 15px;
}

.powermail_submit {
    margin-bottom:20px;
}

.admin-field-wrap {
	padding:10px 0;
	border-bottom:1px dotted #999;
}

.admin-field-wrap select {
	display:inline;
}

.admin-field-wrap textarea {
	width:600px;
	height:200px;
	vertical-align:top;
}

.form-group {
	input[type=text],
	input.form-control,
	textarea,
	.select2-container .select2-selection {
		line-height: 18px;
		border: 1px solid rgba(198,198,198,0.52);
		border-radius: $global-radius;
		background: $grey-table;
		color: $black-light;
		margin-bottom: 10px;
		padding: 6px 10px;
		height: auto;
		.select2-selection__rendered {
			height: auto;
			line-height: 18px;
			padding: 0;
			font-weight: 300;
		}
	}

	.select2-container {
		width: 100% !important;
	}
}

.select2-container--open {
	.select2-dropdown {
		border-color: rgba(198,198,198,0.52);
	}
	.select2-dropdown--below {
		top: -10px;
		border-top: none;
	}
}

.select2-results__option--highlighted {
	background-color: $blue-light !important;
}



/* Tables */
table {
	margin:20px 0 40px;
	width:100%;
}

th,td {
	text-align:left;
	vertical-align:top;
	padding:5px 20px;
	border-top:1px dotted #999;
}

th {
	text-transform:uppercase;
	background:#eee;
}

th label,td label {
	width:75px;
	vertical-align:baseline;
}

.biglabels {
    th, tr td:first-child {
        width:30%;
    }
    th {
        text-transform: none;
        font-weight: normal;
	    background: $grey-light !important;
	    color: $black-light !important;
	    vertical-align: middle;
	    border-bottom: 1px solid $white !important;
    }
	td,th {
		&.biglist-divider {
			background: none !important;
		}
	}
}

.biglist-divider {
    margin-top: 20px;
    padding-top:20px;
    padding-bottom:20px;
    border-top:3px solid;
    background:#333;
    font-size: 14px;
    h3 {
        margin:0 0 5px 0;
        padding:0;
        font-size: 20px;
    }
}
.biglist-subdivider {
    padding-top:10px;
    padding-bottom:10px;
    background: none !important;
    text-transform: uppercase;
    font-size: 16px;
    h4 {
        margin:0 0 5px 0;
        padding:0;
        font-size: 16px;
    }
}

.admin-actions-col {
	width:100px;
}

.alert {
	position: absolute;
	top:0;
	border-radius: $global-radius;
}

.alert-success {
	background: $green;
	padding:15px 65px;
	color:#fff;
	font-size:16px;
	display:inline-block;
}

.div-trigger {
    padding: 25px 16px;
    border-top:1px solid #ddd;
    cursor: pointer;
	font-size: 28px;
	color: $red;
	text-transform: none;
	position: relative;
	margin: 0;
	&:after {
		content: '';
		position: absolute;
		right: 30px;
		top: 26px;
		display: inline-block;
		background-image: url('/assets/img/project/admin-icons/toggle-plus.png');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100% auto;
		width: 28px;
		height: 28px;
	}
	&.active {
		&:after {
			background-image: url('/assets/img/project/admin-icons/toggle-minus.png');
		}
	}
	@media (max-width: $mobile-max) {
		padding: 15px 20px 15px 0;
		font-size: 24px;
		&:after {
			right: 0;
			top: 15px;
			width: 20px;
			height: 20px;
		}
	}
}

.sidebar-div-toggle {
	position: fixed;
	padding-top: 46px;
	.div-trigger {
		padding: 5px 10px;
		cursor: pointer;
		font-size: 16px;
		border-top: none;
		border-bottom: 1px solid $grey;
		color: $black-light;
		&:after {
			display: none;
		}
		&.active,
		&:hover {
			background: $grey-dark;
			color: $white;
		}
	}
	@media (max-width: $mobile-max) {
		display: none;
	}
}

.div-container {
    display: none;
	//margin: 0 -5%;
	padding-bottom: 20px;
    h4 {
		clear: both;
        font-size: 16px;
	    font-weight: 400;
        margin: 20px 0 10px;
        padding: 20px 15px;
    }
	> h4 {
		width: 80%;
	}
    h4:first-child {
        border-top: 0;
        margin-top:0;
    }
    .col-md-4, .col-md-8 {
        h4 {
            padding: 20px 14px 20px 0;
        }
    }

	.div-container-inner {
		//padding: 0 5%;
		&.col-sm-12 {
			padding: 0 15px;
		}
		h4 {
			padding: 20px 0;
		}
		> h4 {
			padding: 20px 15px;
		}
		.form-group {
			padding: 0 15px;
		}
	}
	.div-container-special {

		.form-group {
			padding: 0;
			width: 100%;
		}
	}

	.form-group {
		//float: left;
		//width: 45%;
		//padding: 0 5%;
		.form-group {
			padding: 0 15px;
		}
		.col-sm-1 {
			padding: 0;
		}
	}

	.form-group-inner {
		padding: 0 15px;
	}

}

.divfield-trigger {
    padding:20px 16px;
    margin: 0 0 20px 0;
    border-top:1px solid #ddd;
    cursor: pointer;
    background: #ededed;
}

.divfield-container {
    display: none;
    padding: 0 10px 0 20px;
    border-left: 1px dotted #ddd;
    margin: 10px 0 30px 18px;
    h4 {
        font-size: 18px;
        margin: 20px 0 10px;
        padding: 20px 14px;
        border-top: 1px solid #ddd;
    }
    h4:first-child {
        border-top: 0;
        margin-top:0;
    }

	@media (max-width: $mobile-max) {
		padding-left: 6px;
		margin-left: 0;
		.col-sm-4,
		.col-sm-8 {
			padding: 0;
		}
	}
}

.felogin-wrap {
	padding-top: 150px;
}

.login-section fieldset legend, .tx-felogin-pi1 legend {
    display: none;
}

.tx-felogin-pi1 {
    h1 {
        padding: 30px 30px 30px 0;
    }
    .login-error {
        color: #d00;
    }
    label {
        text-transform: uppercase;
    }
}

#add-sector, #add-product, #add-service, #add-contact, #add-quality, #add-membership {
    cursor: pointer;
    float: right;
    text-decoration: none !important;
    span {
        font-weight: 700;
        vertical-align:baseline;
        border:1px solid #ccc;
        padding:1px 5px;
        margin-left: 5px;
    }
}

#remove-contact,
#remove-contact-2,
#remove-contact-3 {
    cursor: pointer;
    float: right;
    text-decoration: none !important;
    span {
        font-weight: 700;
        vertical-align:baseline;
        border:1px solid #ccc;
        padding:1px 5px;
        margin-left: 5px;
    }
}

.quality_cert {
	
}

.sector-listitem, .product-listitem, .service-listitem, .membership-listitem {
    display: none;
    &:first-child {
        display: inline-block;
    }
}
.membership-listitem-first {
    display: block;
}
.product-listitem, .service-listitem {
	display: inline-block;
    &.field_hidden {
        display: none;
    }
}

#cp_1 {
	padding-bottom: 20px;
	&:after {
		content: '';
		display: block;
		clear: both;
	}
}

.cp_new {
	clear: both;
	padding: 20px 0;
	border-top: 1px solid $grey;
	&:after {
		content: '';
		display: block;
		clear: both;
	}
}

.checkbox-description {
	float: left;
	padding-left: 26px;
}

.product-categories-panel .panel-group {
    margin-bottom:0;
}
.product-categories-panel .panel-body {
    padding:0 !important;
    margin-left:30px;
}

.panel-group .panel-heading + .panel-collapse > .panel-body, .panel-group .panel-heading + .panel-collapse > .list-group {
    border-top:0;
}

.header-description {
    padding:0;
}

.gender-checkbox-container {
    margin-bottom:10px !important;
}

.linkedin_link,
.xing_link {
	padding-left: 32px;
	background-repeat: no-repeat;
	background-size: 22px 22px;
	line-height: 22px;
	background-position: left center;
	display: inline-block;
}

.linkedin_link {
	color: $blue;
	background-image: url('/assets/img/project/admin-icons/ic-linkedin.png');
	&:hover {
		color: $blue;
	}
}

.xing_link {
	margin-left: 30px;
	color: $green-hover;
	background-image: url('/assets/img/project/admin-icons/ic-xing.png');
	&:hover {
		color: $green-hover;
	}
}

#tab_companies,
#tab_partners,
#tab_messages {
	h2 {
		margin-bottom: 10px;
	}
	.tx_ineast {
		margin-top: 20px;
	}
}