/* Buttons and CTAs */
.admin-new-cta,.admin-submit {
	margin:5px 0;
	padding:10px 20px 10px 40px;
	color:#eee;
	border-radius: 2px;
	font-size: 14px;
	text-transform:uppercase;
	text-decoration:none;
	background:url(/assets/img/project/admin-icons/ic-plus-b.png) #333 no-repeat;
	background-size: 15px 15px;
	background-position:9px 9px;
	display:inline-block;
	float:right;
}

.admin-new-cta:hover,.admin-submit:hover {
	//color:#fff;
	//text-decoration:none;
	//background:url(/assets/img/project/icon/add.png) #000 no-repeat;
	//background-size:16px 16px;
	//background-position:9px 9px;
}

.admin-new-cta {
	display:inline;
	text-align:right;
	background-color: $green;
	color: $black-light;
	line-height: 20px;
	background-position: 9px center;
	margin: 0;
	font-weight: 400;
	&:hover {
		background-color: $green-hover;
		color: $black-light;
		text-decoration: none;
	}
}

.admin-icon {
	overflow: hidden;
	width: 24px;
	height: 24px;
	color: $white !important;
	border-radius: $global-radius;
	text-indent: -2000px;
	display:block;
	margin: 0 5px;
	font-weight: 400;
	font-family: 'Glyphicons Halflings';
	&:before {
		text-indent: 0;
		font-size: 12px;
		display: block;
		line-height: 24px;
		text-align: center;
	}
	&:hover {
		text-decoration: none;
	}

	@media (min-width: 1400px) {
		float: left;
	}
}

.admin-edit-icon {
	//background-image: url(/assets/img/project/icon/writing.png);
	background-color: $grey-dark;
	&:before { content: "\270f"; }
}

.admin-delete-icon {
	//background-image: url(/assets/img/project/icon/rubbish-bin.png);
	background-color: $orange;
	&:before { content: "\e020"; }
}

h1 {
	.admin-icon {
		float: none;
		display: inline-block;
		margin: 0 10px;
	}
}

.btn-link,
.powermail_submit {
	background-color: $blue-light !important;
	font-weight: 300;
	text-transform: uppercase;
	line-height: 20px;
	font-family: $body-font;
	font-size: 14px;
	border-radius: $global-radius;
	&:hover {
		background-color: $blue !important;
	}
}