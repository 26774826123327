.panel-group {
	border-top: 1px solid $grey;
	.panel {
		border-radius: 0;
		border-left: none;
		border-right: none;
		border-top: none;
		border-color: $grey;
		margin: 0 !important;
		box-shadow: none !important;
		.panel {
			.panel-title {
				font-size: 14px;
				a {
					color: $black-light;
				}
			}
		}
		.panel-body {
			@media (max-width: $mobile-max) {
				margin-left: 15px;
			}
		}
	}
	.panel-heading {
		border: none;
		padding: 0;
		background: none;
	}
	.panel-title {
		margin: 0;
		padding: 0;
		a {
			color: $red;
			display: block;
			padding: 20px 15px;
			background-repeat: no-repeat;
			background-position: right center;
			background-size: 20px 20px;
			background-image: url(/assets/img/project/admin-icons/toggle-minus.png);
			text-decoration: none !important;
			&.collapsed {
				background-image: url(/assets/img/project/admin-icons/toggle-plus.png);
			}
			@media (max-width: $mobile-max) {
				padding: 15px 15px 15px 0;
				background-size: 15px 15px;
			}
		}
	}
}