.nav-tabs {
	overflow: hidden;
	border-bottom: 1px solid $primary;
	li {
		width: 150px;
		height: 40px;
		position: relative;
		bottom: -10px;
		background: $grey-dark;
		transition: all 0.5s;
		margin-right: 5px;
		a {
			background: none !important;
			color: $white !important;
			font-size: 14px;
			line-height: 30px;
			border: none !important;
			border-radius: 0;
			margin: 0 !important;
			padding: 0;
			text-align: center;
			transition: all 0.5s;
		}
		&.active {
			background: $primary;
			bottom: 0;
			a {
				line-height: 40px;
			}
		}
		&:hover {
			background: $primary;
		}
	}
}

.tab-pane {
	.tx_ineast {
		margin-top: 0;
	}
}