

.admin-container-header {
	background: $black;
	position:fixed;
	top:0;
	z-index:100;
	width:100%;
	height: 40px;
}

/* Logo and items */
.admin-main-logo {
	display: block;
	padding: 5px 15px 5px 13px;
	border-right: 1px solid $white;
	line-height: 30px;
	color: $white;
	float: left;
	width: 206px;
	img {
		float: left;
		width: auto;
		height: 24px;
		margin-right: 15px;
	}
	&:hover {
		text-decoration: none;
		color: $white;
	}
	@media (max-width: $mobile-max) {
		padding-left: 0;
		width: 190px;
	}
}

.admin-filter {
	float: left;
	position: relative;
	color: $white;
	&-toggle {
		padding: 0 11px 0 45px;
		line-height: 40px;
		border-right: 1px solid $white;
		text-transform: uppercase;
		font-size: 14px;
		background-image: url('/assets/img/project/admin-icons/ic-filter.png');
		background-repeat: no-repeat;
		background-size: auto 18px;
		background-position: 15px center;
	}
	&.active {
		background: $white;
		color: $black-light;
		.admin-filter-toggle {
			background-image: url('/assets/img/project/admin-icons/ic-filter-b.png');
		}
	}
	@media (max-width: $mobile-max) {
		&-toggle {
			height: 40px;
			width: 40px;
			padding: 0;
			background-position: center center;
		}
	}
}

.admin-login {
	padding: 0 11px;
	border-left: 1px solid $white;
	p {
		margin: 0;
		height: 40px;
		display: table-cell;
		vertical-align: middle;
		color: $white;
		font-size: 12px;
		a {
			color: $white;
			i {
				line-height: 19px;
			}
			span {
				margin-left: 5px;
			}
		}
	}
}

.profile-img {
	padding: 15px 0 0;
	height: 120px;
	img {
		max-width: 100%;
		max-height: 100%;
		vertical-align: middle;
	}
}