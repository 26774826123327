@import url(/assets/bower_components/select2/dist/css/select2.css);
@import url(/assets/bower_components/jquery-ui/themes/base/all.css);
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,600,700");
/* line 3, workfiles/project/css/admin/_l-header.scss */
.admin-container-header {
  background: #2c2d32;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 40px; }

/* Logo and items */
/* line 13, workfiles/project/css/admin/_l-header.scss */
.admin-main-logo {
  display: block;
  padding: 5px 15px 5px 13px;
  border-right: 1px solid #fff;
  line-height: 30px;
  color: #fff;
  float: left;
  width: 206px; }
  /* line 21, workfiles/project/css/admin/_l-header.scss */
  .admin-main-logo img {
    float: left;
    width: auto;
    height: 24px;
    margin-right: 15px; }
  /* line 27, workfiles/project/css/admin/_l-header.scss */
  .admin-main-logo:hover {
    text-decoration: none;
    color: #fff; }
  @media (max-width: 767px) {
    /* line 13, workfiles/project/css/admin/_l-header.scss */
    .admin-main-logo {
      padding-left: 0;
      width: 190px; } }

/* line 37, workfiles/project/css/admin/_l-header.scss */
.admin-filter {
  float: left;
  position: relative;
  color: #fff; }
  /* line 41, workfiles/project/css/admin/_l-header.scss */
  .admin-filter-toggle {
    padding: 0 11px 0 45px;
    line-height: 40px;
    border-right: 1px solid #fff;
    text-transform: uppercase;
    font-size: 14px;
    background-image: url("/assets/img/project/admin-icons/ic-filter.png");
    background-repeat: no-repeat;
    background-size: auto 18px;
    background-position: 15px center; }
  /* line 52, workfiles/project/css/admin/_l-header.scss */
  .admin-filter.active {
    background: #fff;
    color: #4a4a4a; }
    /* line 55, workfiles/project/css/admin/_l-header.scss */
    .admin-filter.active .admin-filter-toggle {
      background-image: url("/assets/img/project/admin-icons/ic-filter-b.png"); }
  @media (max-width: 767px) {
    /* line 60, workfiles/project/css/admin/_l-header.scss */
    .admin-filter-toggle {
      height: 40px;
      width: 40px;
      padding: 0;
      background-position: center center; } }

/* line 69, workfiles/project/css/admin/_l-header.scss */
.admin-login {
  padding: 0 11px;
  border-left: 1px solid #fff; }
  /* line 72, workfiles/project/css/admin/_l-header.scss */
  .admin-login p {
    margin: 0;
    height: 40px;
    display: table-cell;
    vertical-align: middle;
    color: #fff;
    font-size: 12px; }
    /* line 79, workfiles/project/css/admin/_l-header.scss */
    .admin-login p a {
      color: #fff; }
      /* line 81, workfiles/project/css/admin/_l-header.scss */
      .admin-login p a i {
        line-height: 19px; }
      /* line 84, workfiles/project/css/admin/_l-header.scss */
      .admin-login p a span {
        margin-left: 5px; }

/* line 91, workfiles/project/css/admin/_l-header.scss */
.profile-img {
  padding: 15px 0 0;
  height: 120px; }
  /* line 94, workfiles/project/css/admin/_l-header.scss */
  .profile-img img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle; }

/* line 1, workfiles/project/css/admin/_l-footer.scss */
.user-footer {
  padding: 20px 15px; }
  /* line 3, workfiles/project/css/admin/_l-footer.scss */
  .user-footer p {
    line-height: 1.2;
    font-size: 12px;
    font-weight: normal;
    margin: 0; }
  @media (max-width: 767px) {
    /* line 1, workfiles/project/css/admin/_l-footer.scss */
    .user-footer {
      padding: 20px 0; } }

/* line 3, workfiles/project/css/admin/_l-page.scss */
body.page59 .crm-content {
  padding-left: 0; }

/* line 11, workfiles/project/css/admin/_l-page.scss */
.crm-content {
  transition: all 1s cubic-bezier(0.17, 0.04, 0.03, 0.94);
  padding-left: 205px; }
  /* line 14, workfiles/project/css/admin/_l-page.scss */
  .crm-content h1 {
    padding: 30px;
    line-height: 40px;
    margin: 0; }
    /* line 18, workfiles/project/css/admin/_l-page.scss */
    .crm-content h1 a {
      color: #185caa; }
      /* line 20, workfiles/project/css/admin/_l-page.scss */
      .crm-content h1 a.admin-new-cta {
        color: #4a4a4a; }
  @media (max-width: 767px) {
    /* line 11, workfiles/project/css/admin/_l-page.scss */
    .crm-content {
      padding: 0; }
      /* line 27, workfiles/project/css/admin/_l-page.scss */
      .crm-content h1 {
        font-size: 30px;
        line-height: 1.2; } }

/* line 35, workfiles/project/css/admin/_l-page.scss */
.crm-admin-empty h1 {
  padding: 30px 0;
  line-height: 40px;
  margin: 0; }

/* line 40, workfiles/project/css/admin/_l-page.scss */
.crm-admin-empty .crm-table-wrap {
  padding: 20px 0;
  margin: 0 -15px; }

/* line 46, workfiles/project/css/admin/_l-page.scss */
.crm-table-wrap {
  padding: 30px;
  margin-right: -15px;
  background: #fff; }
  /* line 50, workfiles/project/css/admin/_l-page.scss */
  .crm-table-wrap table {
    min-width: 1000px;
    margin-top: 20px; }
    /* line 56, workfiles/project/css/admin/_l-page.scss */
    .crm-table-wrap table thead tr th,
    .crm-table-wrap table tbody tr th {
      border: none;
      background: #255fac;
      color: #fff;
      font-weight: 400;
      padding: 10px 10px;
      position: relative; }
      /* line 63, workfiles/project/css/admin/_l-page.scss */
      .crm-table-wrap table thead tr th.th-datetime,
      .crm-table-wrap table tbody tr th.th-datetime {
        width: 110px; }
      /* line 66, workfiles/project/css/admin/_l-page.scss */
      .crm-table-wrap table thead tr th.th-status,
      .crm-table-wrap table tbody tr th.th-status {
        max-width: 100px; }
      /* line 69, workfiles/project/css/admin/_l-page.scss */
      .crm-table-wrap table thead tr th.th-number, .crm-table-wrap table thead tr th.th-checkbox,
      .crm-table-wrap table tbody tr th.th-number,
      .crm-table-wrap table tbody tr th.th-checkbox {
        width: 50px; }
      /* line 73, workfiles/project/css/admin/_l-page.scss */
      .crm-table-wrap table thead tr th.th-actions, .crm-table-wrap table thead tr th.admin-actions-col,
      .crm-table-wrap table tbody tr th.th-actions,
      .crm-table-wrap table tbody tr th.admin-actions-col {
        max-width: 120px; }
      /* line 77, workfiles/project/css/admin/_l-page.scss */
      .crm-table-wrap table thead tr th.sorting-asc, .crm-table-wrap table thead tr th.sorting-desc,
      .crm-table-wrap table tbody tr th.sorting-asc,
      .crm-table-wrap table tbody tr th.sorting-desc {
        color: #3c92e7;
        background: #eee; }
      /* line 82, workfiles/project/css/admin/_l-page.scss */
      .crm-table-wrap table thead tr th.sorting-column,
      .crm-table-wrap table tbody tr th.sorting-column {
        cursor: pointer; }
      /* line 85, workfiles/project/css/admin/_l-page.scss */
      .crm-table-wrap table thead tr th .sorting-icon,
      .crm-table-wrap table tbody tr th .sorting-icon {
        display: block;
        width: 9px;
        height: 16px;
        background-image: url("/assets/img/project/admin-icons/select-inactive.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% auto; }
      /* line 95, workfiles/project/css/admin/_l-page.scss */
      .crm-table-wrap table thead tr th.sorting-asc .sorting-icon,
      .crm-table-wrap table tbody tr th.sorting-asc .sorting-icon {
        background-image: url("/assets/img/project/admin-icons/select-up.png"); }
      /* line 100, workfiles/project/css/admin/_l-page.scss */
      .crm-table-wrap table thead tr th.sorting-desc .sorting-icon,
      .crm-table-wrap table tbody tr th.sorting-desc .sorting-icon {
        background-image: url("/assets/img/project/admin-icons/select-down.png"); }
      @media all and (min-width: 1400px) {
        /* line 56, workfiles/project/css/admin/_l-page.scss */
        .crm-table-wrap table thead tr th,
        .crm-table-wrap table tbody tr th {
          padding: 10px 30px 10px 10px; }
          /* line 109, workfiles/project/css/admin/_l-page.scss */
          .crm-table-wrap table thead tr th.th-datetime,
          .crm-table-wrap table tbody tr th.th-datetime {
            width: 142px;
            max-width: none; }
          /* line 113, workfiles/project/css/admin/_l-page.scss */
          .crm-table-wrap table thead tr th.th-status,
          .crm-table-wrap table tbody tr th.th-status {
            width: 100px;
            max-width: none; }
          /* line 117, workfiles/project/css/admin/_l-page.scss */
          .crm-table-wrap table thead tr th.th-number, .crm-table-wrap table thead tr th.th-checkbox,
          .crm-table-wrap table tbody tr th.th-number,
          .crm-table-wrap table tbody tr th.th-checkbox {
            width: 50px;
            max-width: none; }
          /* line 122, workfiles/project/css/admin/_l-page.scss */
          .crm-table-wrap table thead tr th.th-actions, .crm-table-wrap table thead tr th.admin-actions-col,
          .crm-table-wrap table tbody tr th.th-actions,
          .crm-table-wrap table tbody tr th.admin-actions-col {
            width: 120px;
            max-width: none; }
          /* line 127, workfiles/project/css/admin/_l-page.scss */
          .crm-table-wrap table thead tr th .sorting-icon,
          .crm-table-wrap table tbody tr th .sorting-icon {
            display: inline-block;
            margin-left: 7px;
            margin-right: -16px;
            margin-top: 3px;
            margin-bottom: -3px; } }
    /* line 140, workfiles/project/css/admin/_l-page.scss */
    .crm-table-wrap table tbody tr td {
      border: none;
      background: #f3f3f3;
      border-bottom: 1px solid #fff;
      vertical-align: middle;
      height: 50px;
      padding: 5px 10px; }
      /* line 147, workfiles/project/css/admin/_l-page.scss */
      .crm-table-wrap table tbody tr td a {
        color: #2273d1; }
        /* line 149, workfiles/project/css/admin/_l-page.scss */
        .crm-table-wrap table tbody tr td a:hover {
          color: #3c92e7; }
      /* line 155, workfiles/project/css/admin/_l-page.scss */
      .crm-table-wrap table tbody tr td .admin-edit-icon {
        margin-bottom: 5px; }
      @media all and (min-width: 1400px) {
        /* line 140, workfiles/project/css/admin/_l-page.scss */
        .crm-table-wrap table tbody tr td {
          padding: 5px 30px 5px 10px; }
          /* line 163, workfiles/project/css/admin/_l-page.scss */
          .crm-table-wrap table tbody tr td .admin-edit-icon {
            margin-bottom: 0; } }
  @media (max-width: 767px) {
    /* line 46, workfiles/project/css/admin/_l-page.scss */
    .crm-table-wrap {
      padding: 15px;
      margin-left: 10px; } }

/* line 178, workfiles/project/css/admin/_l-page.scss */
.label {
  font-weight: 300;
  color: #fff;
  display: block;
  padding: 5px 10px;
  float: left;
  text-transform: uppercase; }

/* line 187, workfiles/project/css/admin/_l-page.scss */
.message-sent,
.message-received {
  border-radius: 2px;
  margin-bottom: 10px;
  padding-top: 15px; }
  /* line 192, workfiles/project/css/admin/_l-page.scss */
  .message-sent hr,
  .message-received hr {
    margin: 10px 0; }
  /* line 196, workfiles/project/css/admin/_l-page.scss */
  .message-sent .message-header p,
  .message-received .message-header p {
    margin-bottom: 0; }
  /* line 200, workfiles/project/css/admin/_l-page.scss */
  .message-sent .message-header-date:after,
  .message-received .message-header-date:after {
    content: '';
    display: block;
    clear: both; }
  /* line 205, workfiles/project/css/admin/_l-page.scss */
  .message-sent .message-header-date > *,
  .message-received .message-header-date > * {
    float: left;
    display: block;
    line-height: 15px;
    margin-right: 5px; }
  /* line 214, workfiles/project/css/admin/_l-page.scss */
  .message-sent h4,
  .message-received h4 {
    margin-top: 30px; }
  /* line 218, workfiles/project/css/admin/_l-page.scss */
  .message-sent .message-attachments,
  .message-received .message-attachments {
    margin-top: 0;
    max-width: 400px; }
    /* line 221, workfiles/project/css/admin/_l-page.scss */
    .message-sent .message-attachments td,
    .message-received .message-attachments td {
      background: none;
      height: auto; }
      /* line 224, workfiles/project/css/admin/_l-page.scss */
      .message-sent .message-attachments td a,
      .message-received .message-attachments td a {
        color: #c70c00; }
        /* line 226, workfiles/project/css/admin/_l-page.scss */
        .message-sent .message-attachments td a:hover,
        .message-received .message-attachments td a:hover {
          color: #c70c00;
          opacity: 0.5; }

/* line 235, workfiles/project/css/admin/_l-page.scss */
.message-received {
  background: #eee;
  color: #4a4a4a; }
  /* line 238, workfiles/project/css/admin/_l-page.scss */
  .message-received hr {
    border-color: #4a4a4a; }
  /* line 244, workfiles/project/css/admin/_l-page.scss */
  .message-received .message-header p a {
    color: #4a4a4a; }
  /* line 250, workfiles/project/css/admin/_l-page.scss */
  .message-received .message-attachments td {
    border-color: #4a4a4a; }

/* line 256, workfiles/project/css/admin/_l-page.scss */
.message-sent {
  background: #3c92e7;
  color: #fff; }
  /* line 262, workfiles/project/css/admin/_l-page.scss */
  .message-sent .message-header p a {
    color: #fff; }
  /* line 268, workfiles/project/css/admin/_l-page.scss */
  .message-sent h4 {
    color: #fff; }

/* line 273, workfiles/project/css/admin/_l-page.scss */
.messages-unread {
  color: #c70c00;
  font-weight: bold; }

/* line 1, workfiles/project/css/admin/_m-filter.scss */
.admin-filter,
.admin-filter-box {
  display: none; }

/* line 9, workfiles/project/css/admin/_m-filter.scss */
body.page60 .admin-filter,
body.page61 .admin-filter,
body.page62 .admin-filter {
  display: block; }

/* line 14, workfiles/project/css/admin/_m-filter.scss */
.admin-filter-box {
  position: absolute;
  padding: 10px 20px 0;
  top: 40px;
  left: -1px;
  width: 350px;
  background: #fff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2); }
  /* line 22, workfiles/project/css/admin/_m-filter.scss */
  .admin-filter-box h4 {
    font-size: 12px;
    font-weight: 600;
    text-transform: none; }
    /* line 26, workfiles/project/css/admin/_m-filter.scss */
    .admin-filter-box h4 label {
      font-weight: 600; }
  /* line 30, workfiles/project/css/admin/_m-filter.scss */
  .admin-filter-box hr {
    margin: 10px 0; }
  /* line 33, workfiles/project/css/admin/_m-filter.scss */
  .admin-filter-box label {
    margin-bottom: 10px; }
  /* line 36, workfiles/project/css/admin/_m-filter.scss */
  .admin-filter-box .project-sword,
  .admin-filter-box .project-category,
  .admin-filter-box .company-sword,
  .admin-filter-box .partner-sword {
    width: 250px;
    border: 1px solid #e1e1e1; }
  /* line 43, workfiles/project/css/admin/_m-filter.scss */
  .admin-filter-box input[type=checkbox] {
    margin: 0 10px 0 0; }
  @media (max-width: 767px) {
    /* line 14, workfiles/project/css/admin/_m-filter.scss */
    .admin-filter-box {
      left: auto;
      right: 0;
      width: 245px; }
      /* line 50, workfiles/project/css/admin/_m-filter.scss */
      .admin-filter-box .project-sword,
      .admin-filter-box .project-category,
      .admin-filter-box .company-sword,
      .admin-filter-box .partner-sword {
        width: 205px; } }

/* line 58, workfiles/project/css/admin/_m-filter.scss */
input[type=checkbox],
input[type=radio] {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
  margin-right: 10px;
  margin-top: 1px;
  margin-bottom: 1px;
  float: left; }

/* line 73, workfiles/project/css/admin/_m-filter.scss */
input[type=checkbox] {
  background-image: url("/assets/img/project/admin-icons/check-box-unactive.png"); }
  /* line 75, workfiles/project/css/admin/_m-filter.scss */
  input[type=checkbox]:checked {
    background-image: url("/assets/img/project/admin-icons/check-box-active.png"); }

/* line 80, workfiles/project/css/admin/_m-filter.scss */
input[type=radio] {
  margin-top: 0;
  background-image: url("/assets/img/project/admin-icons/radio-box-unactive.png"); }
  /* line 83, workfiles/project/css/admin/_m-filter.scss */
  input[type=radio]:checked {
    background-image: url("/assets/img/project/admin-icons/radio-box-active.png"); }

/* line 88, workfiles/project/css/admin/_m-filter.scss */
.admin-list-filter-toggle {
  cursor: pointer;
  width: 150px;
  font-weight: 400;
  padding-left: 30px;
  background-image: url("/assets/img/project/admin-icons/ic-filter-b.png");
  background-repeat: no-repeat;
  background-size: auto 18px;
  background-position: left top;
  margin: 0;
  padding: 0 0 20px 30px; }

/* line 101, workfiles/project/css/admin/_m-filter.scss */
.admin-list-filter-box {
  display: none; }
  /* line 103, workfiles/project/css/admin/_m-filter.scss */
  .admin-list-filter-box h3.div-trigger {
    font-size: 18px;
    line-height: 1;
    padding: 10px 10px;
    text-transform: uppercase;
    font-weight: 400; }
    /* line 109, workfiles/project/css/admin/_m-filter.scss */
    .admin-list-filter-box h3.div-trigger:after {
      width: 18px;
      height: 18px;
      top: 5px;
      right: 15px; }
  /* line 117, workfiles/project/css/admin/_m-filter.scss */
  .admin-list-filter-box .columns-group-2 {
    columns: 2; }
  /* line 121, workfiles/project/css/admin/_m-filter.scss */
  .admin-list-filter-box .checkbox-group,
  .admin-list-filter-box .columns-group-3 {
    columns: 3; }
  /* line 125, workfiles/project/css/admin/_m-filter.scss */
  .admin-list-filter-box .columns-group-4 {
    columns: 4; }
  /* line 128, workfiles/project/css/admin/_m-filter.scss */
  .admin-list-filter-box .default-label-small {
    display: block;
    padding-left: 26px;
    vertical-align: top; }
    /* line 134, workfiles/project/css/admin/_m-filter.scss */
    .admin-list-filter-box .default-label-small input {
      margin-left: -26px; }
  /* line 138, workfiles/project/css/admin/_m-filter.scss */
  .admin-list-filter-box .panel-group {
    border: none; }
    /* line 148, workfiles/project/css/admin/_m-filter.scss */
    .admin-list-filter-box .panel-group .panel h4 a {
      padding: 10px; }
    /* line 153, workfiles/project/css/admin/_m-filter.scss */
    .admin-list-filter-box .panel-group .panel .panel-body {
      margin-left: 10px; }
    /* line 156, workfiles/project/css/admin/_m-filter.scss */
    .admin-list-filter-box .panel-group .panel .panel {
      width: 100%;
      float: none; }
    /* line 161, workfiles/project/css/admin/_m-filter.scss */
    .admin-list-filter-box .panel-group:after {
      content: '';
      display: block;
      clear: both; }
  /* line 167, workfiles/project/css/admin/_m-filter.scss */
  .admin-list-filter-box .checkbox-description {
    padding-left: 0; }
  @media (max-width: 767px) {
    /* line 172, workfiles/project/css/admin/_m-filter.scss */
    .admin-list-filter-box .checkbox-group,
    .admin-list-filter-box .columns-group-2,
    .admin-list-filter-box .columns-group-3,
    .admin-list-filter-box .columns-group-4 {
      columns: 1; }
    /* line 184, workfiles/project/css/admin/_m-filter.scss */
    .admin-list-filter-box .panel-group .panel {
      width: 100%;
      margin: 0 !important;
      float: none; }
      /* line 188, workfiles/project/css/admin/_m-filter.scss */
      .admin-list-filter-box .panel-group .panel .panel {
        width: 100%;
        float: none; }
      /* line 192, workfiles/project/css/admin/_m-filter.scss */
      .admin-list-filter-box .panel-group .panel .default-label-small {
        width: 100%;
        margin-right: 0; }
    /* line 197, workfiles/project/css/admin/_m-filter.scss */
    .admin-list-filter-box .panel-group:after {
      content: '';
      display: block;
      clear: both; } }

/* line 206, workfiles/project/css/admin/_m-filter.scss */
.filter-all-reset {
  display: block;
  float: left;
  margin: -39px 0 0 155px;
  background: #c70c00;
  padding: 5px 15px;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  border-radius: 2px; }

/* line 220, workfiles/project/css/admin/_m-filter.scss */
.filter-radio-reset {
  display: block;
  background: #c70c00;
  padding: 5px 10px;
  text-transform: uppercase;
  color: #fff;
  width: 70px;
  font-size: 12px;
  line-height: 1;
  margin-top: 5px;
  cursor: pointer;
  border-radius: 2px;
  text-align: center; }

/* line 235, workfiles/project/css/admin/_m-filter.scss */
.admin-template-wrapp .checkbox-description,
.admin-template-wrapp label small {
  display: none !important; }

/* Buttons and CTAs */
/* line 2, workfiles/project/css/admin/_m-cta.scss */
.admin-new-cta, .admin-submit {
  margin: 5px 0;
  padding: 10px 20px 10px 40px;
  color: #eee;
  border-radius: 2px;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  background: url(/assets/img/project/admin-icons/ic-plus-b.png) #333 no-repeat;
  background-size: 15px 15px;
  background-position: 9px 9px;
  display: inline-block;
  float: right; }

/* line 25, workfiles/project/css/admin/_m-cta.scss */
.admin-new-cta {
  display: inline;
  text-align: right;
  background-color: #7cd438;
  color: #4a4a4a;
  line-height: 20px;
  background-position: 9px center;
  margin: 0;
  font-weight: 400; }
  /* line 34, workfiles/project/css/admin/_m-cta.scss */
  .admin-new-cta:hover {
    background-color: #6dba31;
    color: #4a4a4a;
    text-decoration: none; }

/* line 41, workfiles/project/css/admin/_m-cta.scss */
.admin-icon {
  overflow: hidden;
  width: 24px;
  height: 24px;
  color: #fff !important;
  border-radius: 2px;
  text-indent: -2000px;
  display: block;
  margin: 0 5px;
  font-weight: 400;
  font-family: 'Glyphicons Halflings'; }
  /* line 52, workfiles/project/css/admin/_m-cta.scss */
  .admin-icon:before {
    text-indent: 0;
    font-size: 12px;
    display: block;
    line-height: 24px;
    text-align: center; }
  /* line 59, workfiles/project/css/admin/_m-cta.scss */
  .admin-icon:hover {
    text-decoration: none; }
  @media (min-width: 1400px) {
    /* line 41, workfiles/project/css/admin/_m-cta.scss */
    .admin-icon {
      float: left; } }

/* line 68, workfiles/project/css/admin/_m-cta.scss */
.admin-edit-icon {
  background-color: #9b9b9b; }
  /* line 71, workfiles/project/css/admin/_m-cta.scss */
  .admin-edit-icon:before {
    content: "\270f"; }

/* line 74, workfiles/project/css/admin/_m-cta.scss */
.admin-delete-icon {
  background-color: #f3732a; }
  /* line 77, workfiles/project/css/admin/_m-cta.scss */
  .admin-delete-icon:before {
    content: "\e020"; }

/* line 81, workfiles/project/css/admin/_m-cta.scss */
h1 .admin-icon {
  float: none;
  display: inline-block;
  margin: 0 10px; }

/* line 88, workfiles/project/css/admin/_m-cta.scss */
.btn-link,
.powermail_submit {
  background-color: #3c92e7 !important;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 20px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  border-radius: 2px; }
  /* line 97, workfiles/project/css/admin/_m-cta.scss */
  .btn-link:hover,
  .powermail_submit:hover {
    background-color: #2273d1 !important; }

/* line 1, workfiles/project/css/admin/_m-tabs.scss */
.nav-tabs {
  overflow: hidden;
  border-bottom: 1px solid #185caa; }
  /* line 4, workfiles/project/css/admin/_m-tabs.scss */
  .nav-tabs li {
    width: 150px;
    height: 40px;
    position: relative;
    bottom: -10px;
    background: #9b9b9b;
    transition: all 0.5s;
    margin-right: 5px; }
    /* line 12, workfiles/project/css/admin/_m-tabs.scss */
    .nav-tabs li a {
      background: none !important;
      color: #fff !important;
      font-size: 14px;
      line-height: 30px;
      border: none !important;
      border-radius: 0;
      margin: 0 !important;
      padding: 0;
      text-align: center;
      transition: all 0.5s; }
    /* line 24, workfiles/project/css/admin/_m-tabs.scss */
    .nav-tabs li.active {
      background: #185caa;
      bottom: 0; }
      /* line 27, workfiles/project/css/admin/_m-tabs.scss */
      .nav-tabs li.active a {
        line-height: 40px; }
    /* line 31, workfiles/project/css/admin/_m-tabs.scss */
    .nav-tabs li:hover {
      background: #185caa; }

/* line 38, workfiles/project/css/admin/_m-tabs.scss */
.tab-pane .tx_ineast {
  margin-top: 0; }

/* jquery ui datepicker month year picker */
/* line 3, workfiles/project/css/admin/_m-datepicker.scss */
.ui-datepicker .ui-datepicker-select-month td,
.ui-datepicker .ui-datepicker-select-year td {
  line-height: 3.4em; }

/* line 7, workfiles/project/css/admin/_m-datepicker.scss */
.ui-datepicker .ui-datepicker-select-month td span,
.ui-datepicker .ui-datepicker-select-month td a,
.ui-datepicker .ui-datepicker-select-year td span,
.ui-datepicker .ui-datepicker-select-year td a {
  font-size: 1.5em;
  text-align: center; }

/* line 14, workfiles/project/css/admin/_m-datepicker.scss */
.ui-datepicker .ui-datepicker-select-year td.outoffocus {
  opacity: 0.5; }

/* line 20, workfiles/project/css/admin/_m-datepicker.scss */
.ui-datepicker.ui-widget .ui-datepicker-header {
  background: none;
  border: none; }
  /* line 23, workfiles/project/css/admin/_m-datepicker.scss */
  .ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-title {
    font-weight: 400; }
  /* line 29, workfiles/project/css/admin/_m-datepicker.scss */
  .ui-datepicker.ui-widget .ui-datepicker-header .ui-state-hover {
    background: none;
    border: none;
    top: 2px; }
    /* line 33, workfiles/project/css/admin/_m-datepicker.scss */
    .ui-datepicker.ui-widget .ui-datepicker-header .ui-state-hover.ui-datepicker-next {
      right: 2px; }
    /* line 36, workfiles/project/css/admin/_m-datepicker.scss */
    .ui-datepicker.ui-widget .ui-datepicker-header .ui-state-hover.ui-datepicker-prev {
      left: 2px; }

/* line 41, workfiles/project/css/admin/_m-datepicker.scss */
.ui-datepicker.ui-widget th {
  padding: 5px;
  font-weight: 400;
  background: #3c92e7;
  color: #fff; }

/* line 48, workfiles/project/css/admin/_m-datepicker.scss */
.ui-datepicker.ui-widget td a {
  color: #4a4a4a;
  background: none; }
  /* line 51, workfiles/project/css/admin/_m-datepicker.scss */
  .ui-datepicker.ui-widget td a.ui-state-active {
    background: #185caa;
    border: 1px solid #185caa;
    color: #fff; }
  /* line 56, workfiles/project/css/admin/_m-datepicker.scss */
  .ui-datepicker.ui-widget td a.ui-state-highlight {
    background: #3c92e7;
    border: 1px solid #3c92e7;
    color: #fff; }

/* line 1, workfiles/project/css/admin/_m-accordion.scss */
.panel-group {
  border-top: 1px solid #e1e1e1; }
  /* line 3, workfiles/project/css/admin/_m-accordion.scss */
  .panel-group .panel {
    border-radius: 0;
    border-left: none;
    border-right: none;
    border-top: none;
    border-color: #e1e1e1;
    margin: 0 !important;
    box-shadow: none !important; }
    /* line 12, workfiles/project/css/admin/_m-accordion.scss */
    .panel-group .panel .panel .panel-title {
      font-size: 14px; }
      /* line 14, workfiles/project/css/admin/_m-accordion.scss */
      .panel-group .panel .panel .panel-title a {
        color: #4a4a4a; }
    @media (max-width: 767px) {
      /* line 19, workfiles/project/css/admin/_m-accordion.scss */
      .panel-group .panel .panel-body {
        margin-left: 15px; } }
  /* line 25, workfiles/project/css/admin/_m-accordion.scss */
  .panel-group .panel-heading {
    border: none;
    padding: 0;
    background: none; }
  /* line 30, workfiles/project/css/admin/_m-accordion.scss */
  .panel-group .panel-title {
    margin: 0;
    padding: 0; }
    /* line 33, workfiles/project/css/admin/_m-accordion.scss */
    .panel-group .panel-title a {
      color: #c70c00;
      display: block;
      padding: 20px 15px;
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 20px 20px;
      background-image: url(/assets/img/project/admin-icons/toggle-minus.png);
      text-decoration: none !important; }
      /* line 42, workfiles/project/css/admin/_m-accordion.scss */
      .panel-group .panel-title a.collapsed {
        background-image: url(/assets/img/project/admin-icons/toggle-plus.png); }
      @media (max-width: 767px) {
        /* line 33, workfiles/project/css/admin/_m-accordion.scss */
        .panel-group .panel-title a {
          padding: 15px 15px 15px 0;
          background-size: 15px 15px; } }

/* line 15, workfiles/project/css/admin.scss */
* {
  box-sizing: border-box; }
  /* line 17, workfiles/project/css/admin.scss */
  *:focus {
    outline: 0 !important; }

/* line 22, workfiles/project/css/admin.scss */
body {
  margin: 0 auto;
  padding: 40px 0 30px;
  background: #e1e1e1;
  font-family: "Source Sans Pro", sans-serif; }

/* Grid */
/* line 30, workfiles/project/css/admin.scss */
.admin-container {
  padding: 0;
  margin-top: 0; }

/* Navigation */
/* line 36, workfiles/project/css/admin.scss */
.admin-main-navigation {
  position: fixed;
  z-index: 10;
  width: 220px;
  background: #185caa;
  left: 0;
  top: 40px;
  bottom: 0;
  padding-left: 0;
  transition: all 1s cubic-bezier(0.17, 0.04, 0.03, 0.94); }
  /* line 46, workfiles/project/css/admin.scss */
  .admin-main-navigation-content {
    float: left;
    height: 100%;
    width: 195px;
    padding-top: 20px; }
    /* line 51, workfiles/project/css/admin.scss */
    .admin-main-navigation-content ul {
      padding: 0; }
      /* line 53, workfiles/project/css/admin.scss */
      .admin-main-navigation-content ul li {
        opacity: 0.7;
        width: 100%;
        height: 44px;
        border-bottom: 1px solid #3c92e7;
        margin: 0;
        background-repeat: no-repeat;
        background-size: 18px 16px;
        background-position: 15px center; }
        /* line 62, workfiles/project/css/admin.scss */
        .admin-main-navigation-content ul li a {
          background: none;
          font-weight: 300;
          text-transform: none;
          font-size: 14px;
          line-height: 19px;
          cursor: pointer;
          text-align: left;
          padding-left: 40px; }
        /* line 72, workfiles/project/css/admin.scss */
        .admin-main-navigation-content ul li.active, .admin-main-navigation-content ul li:hover {
          opacity: 1;
          background-color: #3c92e7; }
          /* line 76, workfiles/project/css/admin.scss */
          .admin-main-navigation-content ul li.active a, .admin-main-navigation-content ul li:hover a {
            background: none; }
        /* line 80, workfiles/project/css/admin.scss */
        .admin-main-navigation-content ul li.linkitem-li-60 {
          background-image: url("/assets/img/project/admin-icons/ic-project.png"); }
        /* line 83, workfiles/project/css/admin.scss */
        .admin-main-navigation-content ul li.linkitem-li-61 {
          background-image: url("/assets/img/project/admin-icons/ic-company.png"); }
        /* line 86, workfiles/project/css/admin.scss */
        .admin-main-navigation-content ul li.linkitem-li-62 {
          background-image: url("/assets/img/project/admin-icons/ic-partners.png"); }
        /* line 89, workfiles/project/css/admin.scss */
        .admin-main-navigation-content ul li.linkitem-li-63 {
          background-image: url("/assets/img/project/admin-icons/ic-messages.png"); }
        /* line 92, workfiles/project/css/admin.scss */
        .admin-main-navigation-content ul li.linkitem-li-64 {
          background-image: url("/assets/img/project/admin-icons/ic-documents.png"); }
        /* line 95, workfiles/project/css/admin.scss */
        .admin-main-navigation-content ul li.linkitem-logo {
          background-image: url("/assets/img/project/logo-white-crm.png");
          background-position: 15px 11px; }
  /* line 102, workfiles/project/css/admin.scss */
  .admin-main-navigation-toggle {
    background: #2273d1;
    float: left;
    height: 100%;
    width: 25px; }
    /* line 107, workfiles/project/css/admin.scss */
    .admin-main-navigation-toggle .toggle-button {
      cursor: pointer;
      position: relative;
      top: 225px;
      background: #3c92e7;
      text-align: center;
      line-height: 100px;
      color: #fff; }
  @media (max-width: 767px) {
    /* line 36, workfiles/project/css/admin.scss */
    .admin-main-navigation {
      left: -195px; } }

/* line 123, workfiles/project/css/admin.scss */
.admin-main-navigation li {
  width: 90px;
  height: 90px;
  display: table;
  margin-bottom: 1px; }

/* line 130, workfiles/project/css/admin.scss */
.admin-main-navigation li a {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 12px;
  padding: 5px;
  background: #333;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase; }

/* line 142, workfiles/project/css/admin.scss */
.admin-main-navigation li a:hover, .admin-main-navigation li.active a {
  background: #cc0800;
  color: #fff; }

/* TYPO */
/* line 148, workfiles/project/css/admin.scss */
h1, h2, h3, h4 {
  text-transform: uppercase;
  font-family: "Source Sans Pro", sans-serif; }

/* line 153, workfiles/project/css/admin.scss */
h1 {
  font-size: 32px;
  margin-top: 0; }
  /* line 156, workfiles/project/css/admin.scss */
  h1 + p {
    font-size: 14px;
    padding: 0 30px;
    position: relative;
    top: -30px; }

/* line 164, workfiles/project/css/admin.scss */
h2 {
  font-size: 24px;
  margin-top: 30px; }

/* line 169, workfiles/project/css/admin.scss */
h3 {
  font-size: 18px;
  margin-top: 20px; }

/* line 174, workfiles/project/css/admin.scss */
h4 {
  font-size: 14px;
  margin: 0; }

/* GRID */
/* line 180, workfiles/project/css/admin.scss */
.admin-side-menu {
  width: 15%; }

/* line 184, workfiles/project/css/admin.scss */
.admin-content {
  width: 80%; }

/* line 192, workfiles/project/css/admin.scss */
.tooltip {
  width: 300px;
  max-width: 300px;
  opacity: 1 !important; }
  /* line 196, workfiles/project/css/admin.scss */
  .tooltip-inner {
    max-width: none;
    background: rgba(0, 0, 0, 0.6); }
  /* line 200, workfiles/project/css/admin.scss */
  .tooltip-arrow {
    border-top-color: rgba(0, 0, 0, 0.6) !important; }

/*
.admin-table-opening-times,
.admin-table-files {
    margin-bottom: 20px;
    border:1px solid #ddd;
    td,th {
        padding:5px;
        border:1px solid #ddd;
        text-align: left;
    }
    th {
        padding:10px 5px;
        background:#333;
        color:#ddd;
    }
}
*/
/* NAVIGATION */
/* line 223, workfiles/project/css/admin.scss */
.admin-nav-item {
  display: block; }

/* line 227, workfiles/project/css/admin.scss */
.admin-nav-link {
  display: block;
  padding: 10px 20px;
  color: #000;
  background: #ddd; }

/* line 234, workfiles/project/css/admin.scss */
.admin-nav-link-active {
  color: #fff;
  background: #333; }

/* FORMS */
/* line 242, workfiles/project/css/admin.scss */
.crm-form-wrap h4 {
  margin: 20px 0 15px; }

/* line 249, workfiles/project/css/admin.scss */
.crm-simple-form .div-trigger:after {
  display: none; }

/* line 255, workfiles/project/css/admin.scss */
.form-group {
  margin-bottom: 0; }
  /* line 257, workfiles/project/css/admin.scss */
  .form-group label {
    padding-top: 8px; }

/* line 261, workfiles/project/css/admin.scss */
label {
  font-weight: normal;
  line-height: 18px; }
  /* line 264, workfiles/project/css/admin.scss */
  label small {
    color: #9b9b9b; }

/* line 269, workfiles/project/css/admin.scss */
.form-control {
  background-color: #eee;
  height: auto;
  padding: 8px 20px;
  margin-bottom: 20px;
  box-shadow: none;
  font-weight: 300;
  border-radius: 2px; }

/* line 280, workfiles/project/css/admin.scss */
p.admin-fielddescription, span.admin-fielddescription {
  display: block;
  line-height: 1.2;
  margin-bottom: 15px;
  font-size: 12px;
  opacity: 0.8; }
  /* line 286, workfiles/project/css/admin.scss */
  p.admin-fielddescription.error, span.admin-fielddescription.error {
    display: inline-block;
    color: #fff;
    position: relative;
    top: -5px;
    background-color: red;
    padding: 3px 5px;
    border-radius: 2px;
    position: relative;
    top: -5px; }

/* line 301, workfiles/project/css/admin.scss */
.checkbox-group span.error.admin-fielddescription {
  margin: 15px 0; }

/* line 310, workfiles/project/css/admin.scss */
.form-group + .admin-fielddescription {
  padding-left: 15px; }

/* line 314, workfiles/project/css/admin.scss */
.powermail_submit {
  margin-bottom: 20px; }

/* line 318, workfiles/project/css/admin.scss */
.admin-field-wrap {
  padding: 10px 0;
  border-bottom: 1px dotted #999; }

/* line 323, workfiles/project/css/admin.scss */
.admin-field-wrap select {
  display: inline; }

/* line 327, workfiles/project/css/admin.scss */
.admin-field-wrap textarea {
  width: 600px;
  height: 200px;
  vertical-align: top; }

/* line 334, workfiles/project/css/admin.scss */
.form-group input[type=text],
.form-group input.form-control,
.form-group textarea,
.form-group .select2-container .select2-selection {
  line-height: 18px;
  border: 1px solid rgba(198, 198, 198, 0.52);
  border-radius: 2px;
  background: #f3f3f3;
  color: #4a4a4a;
  margin-bottom: 10px;
  padding: 6px 10px;
  height: auto; }
  /* line 346, workfiles/project/css/admin.scss */
  .form-group input[type=text] .select2-selection__rendered,
  .form-group input.form-control .select2-selection__rendered,
  .form-group textarea .select2-selection__rendered,
  .form-group .select2-container .select2-selection .select2-selection__rendered {
    height: auto;
    line-height: 18px;
    padding: 0;
    font-weight: 300; }

/* line 354, workfiles/project/css/admin.scss */
.form-group .select2-container {
  width: 100% !important; }

/* line 360, workfiles/project/css/admin.scss */
.select2-container--open .select2-dropdown {
  border-color: rgba(198, 198, 198, 0.52); }

/* line 363, workfiles/project/css/admin.scss */
.select2-container--open .select2-dropdown--below {
  top: -10px;
  border-top: none; }

/* line 369, workfiles/project/css/admin.scss */
.select2-results__option--highlighted {
  background-color: #3c92e7 !important; }

/* Tables */
/* line 376, workfiles/project/css/admin.scss */
table {
  margin: 20px 0 40px;
  width: 100%; }

/* line 381, workfiles/project/css/admin.scss */
th, td {
  text-align: left;
  vertical-align: top;
  padding: 5px 20px;
  border-top: 1px dotted #999; }

/* line 388, workfiles/project/css/admin.scss */
th {
  text-transform: uppercase;
  background: #eee; }

/* line 393, workfiles/project/css/admin.scss */
th label, td label {
  width: 75px;
  vertical-align: baseline; }

/* line 399, workfiles/project/css/admin.scss */
.biglabels th, .biglabels tr td:first-child {
  width: 30%; }

/* line 402, workfiles/project/css/admin.scss */
.biglabels th {
  text-transform: none;
  font-weight: normal;
  background: #f8f8f8 !important;
  color: #4a4a4a !important;
  vertical-align: middle;
  border-bottom: 1px solid #fff !important; }

/* line 411, workfiles/project/css/admin.scss */
.biglabels td.biglist-divider, .biglabels th.biglist-divider {
  background: none !important; }

/* line 417, workfiles/project/css/admin.scss */
.biglist-divider {
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 3px solid;
  background: #333;
  font-size: 14px; }
  /* line 424, workfiles/project/css/admin.scss */
  .biglist-divider h3 {
    margin: 0 0 5px 0;
    padding: 0;
    font-size: 20px; }

/* line 430, workfiles/project/css/admin.scss */
.biglist-subdivider {
  padding-top: 10px;
  padding-bottom: 10px;
  background: none !important;
  text-transform: uppercase;
  font-size: 16px; }
  /* line 436, workfiles/project/css/admin.scss */
  .biglist-subdivider h4 {
    margin: 0 0 5px 0;
    padding: 0;
    font-size: 16px; }

/* line 443, workfiles/project/css/admin.scss */
.admin-actions-col {
  width: 100px; }

/* line 447, workfiles/project/css/admin.scss */
.alert {
  position: absolute;
  top: 0;
  border-radius: 2px; }

/* line 453, workfiles/project/css/admin.scss */
.alert-success {
  background: #7cd438;
  padding: 15px 65px;
  color: #fff;
  font-size: 16px;
  display: inline-block; }

/* line 461, workfiles/project/css/admin.scss */
.div-trigger {
  padding: 25px 16px;
  border-top: 1px solid #ddd;
  cursor: pointer;
  font-size: 28px;
  color: #c70c00;
  text-transform: none;
  position: relative;
  margin: 0; }
  /* line 470, workfiles/project/css/admin.scss */
  .div-trigger:after {
    content: '';
    position: absolute;
    right: 30px;
    top: 26px;
    display: inline-block;
    background-image: url("/assets/img/project/admin-icons/toggle-plus.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
    width: 28px;
    height: 28px; }
  /* line 484, workfiles/project/css/admin.scss */
  .div-trigger.active:after {
    background-image: url("/assets/img/project/admin-icons/toggle-minus.png"); }
  @media (max-width: 767px) {
    /* line 461, workfiles/project/css/admin.scss */
    .div-trigger {
      padding: 15px 20px 15px 0;
      font-size: 24px; }
      /* line 491, workfiles/project/css/admin.scss */
      .div-trigger:after {
        right: 0;
        top: 15px;
        width: 20px;
        height: 20px; } }

/* line 500, workfiles/project/css/admin.scss */
.sidebar-div-toggle {
  position: fixed;
  padding-top: 46px; }
  /* line 503, workfiles/project/css/admin.scss */
  .sidebar-div-toggle .div-trigger {
    padding: 5px 10px;
    cursor: pointer;
    font-size: 16px;
    border-top: none;
    border-bottom: 1px solid #e1e1e1;
    color: #4a4a4a; }
    /* line 510, workfiles/project/css/admin.scss */
    .sidebar-div-toggle .div-trigger:after {
      display: none; }
    /* line 513, workfiles/project/css/admin.scss */
    .sidebar-div-toggle .div-trigger.active, .sidebar-div-toggle .div-trigger:hover {
      background: #9b9b9b;
      color: #fff; }
  @media (max-width: 767px) {
    /* line 500, workfiles/project/css/admin.scss */
    .sidebar-div-toggle {
      display: none; } }

/* line 524, workfiles/project/css/admin.scss */
.div-container {
  display: none;
  padding-bottom: 20px; }
  /* line 528, workfiles/project/css/admin.scss */
  .div-container h4 {
    clear: both;
    font-size: 16px;
    font-weight: 400;
    margin: 20px 0 10px;
    padding: 20px 15px; }
  /* line 535, workfiles/project/css/admin.scss */
  .div-container > h4 {
    width: 80%; }
  /* line 538, workfiles/project/css/admin.scss */
  .div-container h4:first-child {
    border-top: 0;
    margin-top: 0; }
  /* line 543, workfiles/project/css/admin.scss */
  .div-container .col-md-4 h4, .div-container .col-md-8 h4 {
    padding: 20px 14px 20px 0; }
  /* line 550, workfiles/project/css/admin.scss */
  .div-container .div-container-inner.col-sm-12 {
    padding: 0 15px; }
  /* line 553, workfiles/project/css/admin.scss */
  .div-container .div-container-inner h4 {
    padding: 20px 0; }
  /* line 556, workfiles/project/css/admin.scss */
  .div-container .div-container-inner > h4 {
    padding: 20px 15px; }
  /* line 559, workfiles/project/css/admin.scss */
  .div-container .div-container-inner .form-group {
    padding: 0 15px; }
  /* line 565, workfiles/project/css/admin.scss */
  .div-container .div-container-special .form-group {
    padding: 0;
    width: 100%; }
  /* line 575, workfiles/project/css/admin.scss */
  .div-container .form-group .form-group {
    padding: 0 15px; }
  /* line 578, workfiles/project/css/admin.scss */
  .div-container .form-group .col-sm-1 {
    padding: 0; }
  /* line 583, workfiles/project/css/admin.scss */
  .div-container .form-group-inner {
    padding: 0 15px; }

/* line 589, workfiles/project/css/admin.scss */
.divfield-trigger {
  padding: 20px 16px;
  margin: 0 0 20px 0;
  border-top: 1px solid #ddd;
  cursor: pointer;
  background: #ededed; }

/* line 597, workfiles/project/css/admin.scss */
.divfield-container {
  display: none;
  padding: 0 10px 0 20px;
  border-left: 1px dotted #ddd;
  margin: 10px 0 30px 18px; }
  /* line 602, workfiles/project/css/admin.scss */
  .divfield-container h4 {
    font-size: 18px;
    margin: 20px 0 10px;
    padding: 20px 14px;
    border-top: 1px solid #ddd; }
  /* line 608, workfiles/project/css/admin.scss */
  .divfield-container h4:first-child {
    border-top: 0;
    margin-top: 0; }
  @media (max-width: 767px) {
    /* line 597, workfiles/project/css/admin.scss */
    .divfield-container {
      padding-left: 6px;
      margin-left: 0; }
      /* line 616, workfiles/project/css/admin.scss */
      .divfield-container .col-sm-4,
      .divfield-container .col-sm-8 {
        padding: 0; } }

/* line 623, workfiles/project/css/admin.scss */
.felogin-wrap {
  padding-top: 150px; }

/* line 627, workfiles/project/css/admin.scss */
.login-section fieldset legend, .tx-felogin-pi1 legend {
  display: none; }

/* line 632, workfiles/project/css/admin.scss */
.tx-felogin-pi1 h1 {
  padding: 30px 30px 30px 0; }

/* line 635, workfiles/project/css/admin.scss */
.tx-felogin-pi1 .login-error {
  color: #d00; }

/* line 638, workfiles/project/css/admin.scss */
.tx-felogin-pi1 label {
  text-transform: uppercase; }

/* line 643, workfiles/project/css/admin.scss */
#add-sector, #add-product, #add-service, #add-contact, #add-quality, #add-membership {
  cursor: pointer;
  float: right;
  text-decoration: none !important; }
  /* line 647, workfiles/project/css/admin.scss */
  #add-sector span, #add-product span, #add-service span, #add-contact span, #add-quality span, #add-membership span {
    font-weight: 700;
    vertical-align: baseline;
    border: 1px solid #ccc;
    padding: 1px 5px;
    margin-left: 5px; }

/* line 656, workfiles/project/css/admin.scss */
#remove-contact,
#remove-contact-2,
#remove-contact-3 {
  cursor: pointer;
  float: right;
  text-decoration: none !important; }
  /* line 662, workfiles/project/css/admin.scss */
  #remove-contact span,
  #remove-contact-2 span,
  #remove-contact-3 span {
    font-weight: 700;
    vertical-align: baseline;
    border: 1px solid #ccc;
    padding: 1px 5px;
    margin-left: 5px; }

/* line 675, workfiles/project/css/admin.scss */
.sector-listitem, .product-listitem, .service-listitem, .membership-listitem {
  display: none; }
  /* line 677, workfiles/project/css/admin.scss */
  .sector-listitem:first-child, .product-listitem:first-child, .service-listitem:first-child, .membership-listitem:first-child {
    display: inline-block; }

/* line 681, workfiles/project/css/admin.scss */
.membership-listitem-first {
  display: block; }

/* line 684, workfiles/project/css/admin.scss */
.product-listitem, .service-listitem {
  display: inline-block; }
  /* line 686, workfiles/project/css/admin.scss */
  .product-listitem.field_hidden, .service-listitem.field_hidden {
    display: none; }

/* line 691, workfiles/project/css/admin.scss */
#cp_1 {
  padding-bottom: 20px; }
  /* line 693, workfiles/project/css/admin.scss */
  #cp_1:after {
    content: '';
    display: block;
    clear: both; }

/* line 700, workfiles/project/css/admin.scss */
.cp_new {
  clear: both;
  padding: 20px 0;
  border-top: 1px solid #e1e1e1; }
  /* line 704, workfiles/project/css/admin.scss */
  .cp_new:after {
    content: '';
    display: block;
    clear: both; }

/* line 711, workfiles/project/css/admin.scss */
.checkbox-description {
  float: left;
  padding-left: 26px; }

/* line 716, workfiles/project/css/admin.scss */
.product-categories-panel .panel-group {
  margin-bottom: 0; }

/* line 719, workfiles/project/css/admin.scss */
.product-categories-panel .panel-body {
  padding: 0 !important;
  margin-left: 30px; }

/* line 724, workfiles/project/css/admin.scss */
.panel-group .panel-heading + .panel-collapse > .panel-body, .panel-group .panel-heading + .panel-collapse > .list-group {
  border-top: 0; }

/* line 728, workfiles/project/css/admin.scss */
.header-description {
  padding: 0; }

/* line 732, workfiles/project/css/admin.scss */
.gender-checkbox-container {
  margin-bottom: 10px !important; }

/* line 736, workfiles/project/css/admin.scss */
.linkedin_link,
.xing_link {
  padding-left: 32px;
  background-repeat: no-repeat;
  background-size: 22px 22px;
  line-height: 22px;
  background-position: left center;
  display: inline-block; }

/* line 746, workfiles/project/css/admin.scss */
.linkedin_link {
  color: #2273d1;
  background-image: url("/assets/img/project/admin-icons/ic-linkedin.png"); }
  /* line 749, workfiles/project/css/admin.scss */
  .linkedin_link:hover {
    color: #2273d1; }

/* line 754, workfiles/project/css/admin.scss */
.xing_link {
  margin-left: 30px;
  color: #6dba31;
  background-image: url("/assets/img/project/admin-icons/ic-xing.png"); }
  /* line 758, workfiles/project/css/admin.scss */
  .xing_link:hover {
    color: #6dba31; }

/* line 766, workfiles/project/css/admin.scss */
#tab_companies h2,
#tab_partners h2,
#tab_messages h2 {
  margin-bottom: 10px; }

/* line 769, workfiles/project/css/admin.scss */
#tab_companies .tx_ineast,
#tab_partners .tx_ineast,
#tab_messages .tx_ineast {
  margin-top: 20px; }
