.user-footer {
	padding: 20px 15px;
	p {
		line-height: 1.2;
		font-size: 12px;
		font-weight: normal;
		margin: 0;
	}
	@media (max-width: $mobile-max) {
		padding: 20px 0;
	}
}