// Colors
$white: #fff;
$black-dark: #000;
$black: #2c2d32;
$black-light: #4a4a4a;

$blue-dark: #185caa;
$blue: #2273d1;
$blue-light: #3c92e7;

$red: #c70c00;
$red-hover: #ffdde2;

$grey-light: #f8f8f8;
$grey-table: #f3f3f3;
$grey: #e1e1e1;
$grey-dark: #9b9b9b;

$orange-dark: #d5853d;
$orange: #f3732a;
$orange-hover: #ffebdf;

$green: #7cd438;
$green-hover: #6dba31;
$greenblue: #00b26a;
$beige: #fceea7;

$primary: $blue-dark;

// MEDIA QUERY
$mobile-max: 767px;
$tablet-min: 768px;

// FONT Link
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,600,700');

$body-font: 'Source Sans Pro', sans-serif;

// Border
$global-radius: 2px;

$transitions: all 0.5s;


