// Login page & pages without sidemenu
body.page59 {
	.crm-content {
		padding-left: 0;
	}
}


// CRM Content

.crm-content {
	transition: all 1s cubic-bezier(0.17, 0.04, 0.03, 0.94);
	padding-left: 205px;
	h1 {
		padding: 30px;
		line-height: 40px;
		margin: 0;
		a {
			color: $primary;
			&.admin-new-cta {
				color: $black-light;
			}
		}
	}
	@media (max-width: $mobile-max) {
		padding: 0;
		h1 {
			font-size: 30px;
			line-height: 1.2;
		}
	}
}

.crm-admin-empty {
	h1 {
		padding: 30px 0;
		line-height: 40px;
		margin: 0;
	}
	.crm-table-wrap {
		padding: 20px 0;
		margin: 0 -15px;
	}
}

.crm-table-wrap {
	padding: 30px;
	margin-right: -15px;
	background: $white;
	table {
		min-width: 1000px;
		margin-top: 20px;
		thead,
		tbody {
			tr {
				th {
					border: none;
					background: #255fac;
					color:#fff;
					font-weight: 400;
					padding: 10px 10px;
					position: relative;
					&.th-datetime {
						width:110px;
					}
					&.th-status {
						max-width: 100px;
					}
					&.th-number,
					&.th-checkbox {
						width: 50px;
					}
					&.th-actions,
					&.admin-actions-col {
						max-width: 120px;
					}
					&.sorting-asc,
					&.sorting-desc {
						color: $blue-light;
						background:#eee;
					}
					&.sorting-column {
						cursor: pointer;
					}
					.sorting-icon {
						display: block;
						width: 9px;
						height: 16px;
						background-image: url('/assets/img/project/admin-icons/select-inactive.png');
						background-position: center center;
						background-repeat: no-repeat;
						background-size: 100% auto;
					}
					&.sorting-asc {
						.sorting-icon {
							background-image: url('/assets/img/project/admin-icons/select-up.png');
						}
					}
					&.sorting-desc {
						.sorting-icon {
							background-image: url('/assets/img/project/admin-icons/select-down.png');
						}
					}

					@media all and (min-width: 1400px) {

						padding: 10px 30px 10px 10px;

						&.th-datetime {
							width:142px;
							max-width: none;
						}
						&.th-status {
							width: 100px;
							max-width: none;
						}
						&.th-number,
						&.th-checkbox {
							width: 50px;
							max-width: none;
						}
						&.th-actions,
						&.admin-actions-col {
							width: 120px;
							max-width: none;
						}
						.sorting-icon {
							display: inline-block;
							margin-left: 7px;
							margin-right: -16px;
							margin-top: 3px;
							margin-bottom: -3px;
						}
					}
				}
			}
		}
		tbody {
			tr {
				td {
					border: none;
					background: $grey-table;
					border-bottom: 1px solid $white;
					vertical-align: middle;
					height: 50px;
					padding: 5px 10px;
					a {
						color: $blue;
						&:hover {
							//text-decoration: none;
							color: $blue-light;
						}
					}

					.admin-edit-icon {
						margin-bottom: 5px;
					}

					@media all and (min-width: 1400px) {

						padding: 5px 30px 5px 10px;

						.admin-edit-icon {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}

	@media (max-width: $mobile-max) {
		padding: 15px;
		margin-left: 10px;
	}
}

.label {
	font-weight: 300;
	color: $white;
	display: block;
	padding: 5px 10px;
	float: left;
	text-transform: uppercase;
}

.message-sent,
.message-received {
	border-radius: 2px;
	margin-bottom: 10px;
	padding-top: 15px;
	hr {
		margin: 10px 0;
	}
	.message-header {
		p {
			margin-bottom: 0;
		}
		&-date {
			&:after {
				content: '';
				display: block;
				clear: both;
			}
			> * {
				float: left;
				display: block;
				line-height: 15px;
				margin-right: 5px;
			}
		}
	}

	h4 {
		margin-top: 30px;
	}

	.message-attachments {
		margin-top: 0;
		max-width: 400px;
		td {
			background: none;
			height: auto;
			a {
				color: $red;
				&:hover {
					color: $red;
					opacity: 0.5;
				}
			}
		}
	}
}

.message-received {
	background: #eee;
	color: $black-light;
	hr {
		border-color: $black-light;
	}

	.message-header {
		p {
			a {
				color: $black-light;
			}
		}
	}
	.message-attachments {
		td {
			border-color: $black-light;
		}
	}
}

.message-sent {
	background: $blue-light;
	color: $white;

	.message-header {
		p {
			a {
				color: $white;
			}
		}
	}

	h4 {
		color: $white;
	}
}

.messages-unread {
	color: $red;
	font-weight: bold;
}